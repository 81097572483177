import React, { useRef, useState, useEffect } from "react";
import "./App.css";
import "./output.css";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useFilePicker } from "use-file-picker";
import { createClient } from "@supabase/supabase-js";

let bWidth = 800;
const jumpWidth = 6;
let jumps = [];
let numbers = [];
let loaded = false;

const App = () => {
  const supabase = createClient(
    "https://wiuurccjqbwpxrswkiyr.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndpdXVyY2NqcWJ3cHhyc3draXlyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTkzMjI5NzIsImV4cCI6MjAzNDg5ODk3Mn0.VeRBF7mffA-pgY0c5vMo7CFt5AHC0NOdJtC9Z00N4zg"
  );
  fabric.devicePixelRatio = window.devicePixelRatio;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  bWidth = window.innerWidth > 800 ? 800 : window.innerWidth * 0.9;
  const { editor, onReady } = useFabricJSEditor();
  let pDimension = 0.5;
  const linkTextInit = "Link erstellen";
  const [linkText, setLinkText] = useState(linkTextInit);
  const [pSize, setPSize] = useState({ width: 40, height: 20 });
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const { openFilePicker } = useFilePicker({
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      const json = JSON.parse(filesContent[0].content);
      importJson(json);
    },
  });

  const _onReady = (canvas) => {
    canvas.selection = false;
    canvas.setWidth(bWidth);
    canvas.setHeight(bWidth * pDimension);
    canvas.setBackgroundColor("white");
    canvas.renderAll();
    onReady(canvas);
  };

  document.onkeydown = function (e) {
    if (
      editor.canvas.getActiveObject() === undefined ||
      editor.canvas.getActiveObject() === null ||
      (editor.canvas.getActiveObject().type === "i-text" &&
        editor.canvas.getActiveObject().id !== undefined)
    )
      return;
    if (e.code === "Delete") {
      editor.canvas.forEachObject((obj) => {
        if (
          obj.type === "i-text" &&
          obj.id === editor.canvas.getActiveObject().id
        ) {
          editor.canvas.remove(obj);
          numbers = numbers.filter((item) => item.id !== obj.id);
        }
      });
      editor.canvas.remove(editor.canvas.getActiveObject());
    }

    editor.canvas.renderAll();
  };

  useEffect(() => {
    if (
      id === undefined ||
      id === null ||
      loaded === true ||
      editor === undefined
    )
      return;
    loaded = true;
    fetchforJSON(id);
  });

  return (
    <div className="App ">
      <div className="App-header bg-slate-900 overflow-auto ">
        <h1 className="mb-4 mt-10 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl ">
          Reitparcours erstellen
        </h1>
        <h4 className="mb-4 text-sm font-extrabold leading-none tracking-tight text-white md:text-3xl  ">
          Online Editor
        </h4>
        <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          Erstellen und bearbeiten Sie Ihren Parcours nach Ihren Wünschen.
          Wählen Sie die Größe des Reitplatzes und fügen Sie Hindernisse hinzu.
          Ergänzen Sie mit Texten und passen Sie, falls nötig, die
          Hindernisnummern an. Schlussendlich können Sie den Parcours teilen.
        </p>
        <div
          className="mt-20 mb-5 grid md:grid-cols-2 grid-cols-1  gap-4 place-items-center"
          style={{ display: "flexbox", alignItems: "center" }}
        >
          <div
            className="grid grid-cols-4"
            style={{ display: "flexbox", alignItems: "center" }}
          >
            <p className=" text-sm pr-5 text-gray-500  dark:text-gray-400  text-justify">
              Platzgröße:
            </p>
            <div className=" w-20">
              <input
                placeholder="40"
                type="text"
                id="small-input"
                ref={inputRef1}
                className="block w-full p-2 text-gray-900 border border-gray-300  bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-20">
              <input
                placeholder="20"
                type="text"
                id="small-input"
                ref={inputRef2}
                className="block w-full p-2 text-gray-900 border border-gray-300  bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div className="w-20 pb-2">
              <button
                onClick={handleSize}
                className="btn btn-blue text-sm inline-flex items-center justify-center w-10 h-10 mr-2 text-indigo-100 transition-colors duration-150 rounded-lg focus:shadow-outline hover:bg-indigo-800"
              >
                →
              </button>
            </div>
          </div>
          <div
            className="grid grid-cols-2"
            style={{ display: "flexbox", alignItems: "center" }}
          >
            <div className="w-40 pb-2">
              <button
                onClick={() => openFilePicker()}
                className="btn bg-blue-900  text-sm inline-flex items-center justify-center w-30 h-10 mr-2 text-indigo-100 transition-colors duration-150 rounded-lg focus:shadow-outline hover:bg-indigo-800"
              >
                Import Datei
              </button>
            </div>
            <div className="w-40 pb-2">
              <button
                onClick={() =>
                  (window.location.href = window.location.href.split("?")[0])
                }
                className="btn bg-blue-900  text-sm inline-flex items-center justify-center w-30 h-10 mr-2 text-indigo-100 transition-colors duration-150 rounded-lg focus:shadow-outline hover:bg-indigo-800"
              >
                Neu
              </button>
            </div>
          </div>
        </div>

        <div>
          <FabricJSCanvas className="sample-canvas" onReady={_onReady} />
        </div>

        <div className="m-5 flex justify-evenly w-3/5 gap-1">
          <div>
            <button
              onClick={(e) => oxer("oxer")}
              className="btn btn-blue text-sm "
            >
              Oxer
            </button>
          </div>
          <div>
            <button
              onClick={(e) => oxer("steil")}
              className="btn btn-blue text-sm "
            >
              Steil
            </button>
          </div>
          <div>
            <button
              onClick={(e) => oxer("triple")}
              className="btn btn-blue text-sm "
            >
              Triplebarre
            </button>
          </div>
          <div>
            <button
              onClick={(e) => oxer("stange")}
              className="btn btn-blue text-sm "
            >
              Stange
            </button>
          </div>
          <div>
            <button onClick={Addtext} className="btn btn-blue text-sm ">
              Text
            </button>
          </div>
        </div>
        {!(
          editor?.canvas?.getActiveObject() === undefined ||
          editor?.canvas?.getActiveObject() === null ||
          (editor?.canvas?.getActiveObject().type === "i-text" &&
            editor?.canvas?.getActiveObject().id !== undefined)
        ) ? (
          <button
            className="mb-3 btn btn-blue text-sm"
            onClick={() => {
              editor.canvas.forEachObject((obj) => {
                if (
                  obj.type === "i-text" &&
                  obj.id === editor.canvas.getActiveObject().id
                ) {
                  editor.canvas.remove(obj);
                  numbers = numbers.filter((item) => item.id !== obj.id);
                }
              });
              editor.canvas.remove(editor.canvas.getActiveObject());
            }}
          >
            Löschen
          </button>
        ) : null}
        <div
          className="m-5 flex justify-evenly w-3/5 gap-2"
          style={{ marginTop: 0 + "px" }}
        >
          <div>
            <button onClick={exportImage} className="btn bg-blue-900 text-sm">
              Export Image
            </button>
          </div>
          <div>
            {linkText !== "" ? (
              <button onClick={exportLink} className="btn bg-blue-900 text-sm">
                {linkText}
              </button>
            ) : false ? (
              <p selectable={true}>{linkText}</p>
            ) : (
              <button
                type="button"
                className="btn bg-blue-900 text-sm"
                disabled
              >
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </button>
            )}
          </div>
          <div>
            <button onClick={exportJSON} className="btn bg-blue-900 text-sm ">
              Export Datei
            </button>
          </div>
        </div>
        <h4 className="mb-10 mt-3 text-2xl font-extrabold text-white leading-none tracking-tight md:text-2xl lg:text-2xl ">
          Anwendung
        </h4>
        <div className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
          <ul className="list-disc text-left">
            <li>Wählen Sie die Größe des Reitplatzes</li>
            <li>Hinzufügen von Hindernissen via den Knöpfen</li>
            <li>
              Ändern der Sprungnummer durch klicken und gewünschten Wert
              eintragen
            </li>
            <li>
              Löschen von Objekten durch auswählen und drücken der
              Entf-Taste/Löschen-Knopf
            </li>
            <li>Hinzufügen von Info Texten durch den Knopf "Text"</li>
          </ul>
        </div>

        <h4 className="mb-10 mt-3 text-4xl font-extrabold leading-none tracking-tight text-white md:text-5xl lg:text-6xl ">
          Beispiele
        </h4>
        <div
          className="grid md:grid-cols-2 grid-cols-1 gap-4 place-items-center"
          style={{ display: "flexbox", alignItems: "center" }}
        >
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src="../assets/1.png"
              alt=""
            />
          </div>
          <div>
            <img
              className="h-auto max-w-full rounded-lg"
              src="../assets/2.png"
              alt=""
            />
          </div>
        </div>
        <footer className="bg-white rounded-lg shadow mt-4 dark:bg-gray-800 mb-3">
          <div className="mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              Created 2024 by{" "}
              <a className="underline" href="mailto:julian.podiebrad@gmx.de">
                Julian P.
              </a>
              {" | "}
              <a className="underline" href="./license.txt">
                License
              </a>
              <br />
              Persönliche Daten werden nur für Zweck verwendet und nicht
              weitergegeben.
            </span>
          </div>
        </footer>
      </div>
    </div>
  );

  async function importJson(json) {
    editor.canvas.loadFromJSON(json.cv, () => {
      pDimension = json.cv.height / json.cv.width;
      setPSize(editor.canvas.pSize);
      inputRef1.current.value = editor.canvas.pSize.width;
      inputRef2.current.value = editor.canvas.pSize.height;
      jumps = json.jumps;

      numbers = json.numbers;

      editor.canvas.forEachObject((obj) => {
        if (obj.type === "image") {
          obj.on("moving", () => rect1Move(obj.id));
          obj.on("rotating", () => rect1Move(obj.id));
          rect1Move(obj.id);
        } else {
        }
      });
      editor.canvas.setHeight((bWidth * json.cv.height) / json.cv.width);
      editor.canvas.setWidth(bWidth);

      scaleObjectsOnCanvas(bWidth / json.cv.width);

      setLinkText(linkTextInit);
      handleSize();
      editor.canvas.renderAll();
    });
  }

  async function fetchforJSON(id) {
    try {
      var link = await supabase.storage
        .from("parcours")
        .getPublicUrl(id + ".json");
      var json = await fetch(link.data.publicUrl);
      json = await json.json();
      if (json.error) throw new Error("Error");
      importJson(json);
    } catch (error) {
      window.location.href = window.location.href.split("?")[0];
      return;
    }
  }

  function exportLink() {
    const id = Math.random() * 10;
    copyToClipboard(window.location.href.split("?")[0] + "?id=" + id);
    exportAsync(id);
  }

  async function exportAsync(id) {
    editor.canvas.pSize = pSize;
    try {
      setLinkText("");
      const response = await supabase.storage.from("parcours").upload(
        id + ".json",
        JSON.stringify({
          cv: editor.canvas.toJSON([
            "id",
            "selectable",
            "hasControls",
            "_controlsVisibility",
            "width",
            "height",
            "pSize",
          ]),
          numbers: numbers,
          jumps: jumps,
        })
      );

      if (response.error || response.data === null) throw new Error("Error");

      setLinkText("Text kopiert");
    } catch (e) {
      setLinkText("Fehler");
    }
  }

  function exportJSON() {
    editor.canvas.pSize = pSize;
    const toBlob = JSON.stringify({
      cv: editor.canvas.toJSON([
        "id",
        "selectable",
        "hasControls",
        "_controlsVisibility",
        "width",
        "height",
        "pSize",
      ]),
      numbers: numbers,
      jumps: jumps,
    });

    const file = new Blob([toBlob], {
      type: "application/text",
    });
    const el = document.createElement("a");
    el.href = URL.createObjectURL(file);
    el.download = "parcours";
    document.body.appendChild(el);
    el.click();
  }

  function exportImage() {
    const name = "parcours.png";
    const uri = editor.canvas.toDataURL("image/png");

    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleSize() {
    if (inputRef2.current.value === "") {
      inputRef2.current.value = 20;
    }
    if (inputRef1.current.value === "") {
      inputRef1.current.value = 40;
    }
    const tDim = inputRef2.current.value / inputRef1.current.value;
    pDimension = tDim;
    setPSize({
      width: inputRef1.current.value,
      height: inputRef2.current.value,
    });
    editor.canvas.setHeight(bWidth * tDim);

    editor.canvas.forEachObject((obj1) => {
      if (obj1.type === "image") {
        const tAngle = obj1.angle;

        obj1.angle = 0;
        obj1.setCoords();
        obj1.scaleToWidth((bWidth / inputRef1.current.value) * jumpWidth);
        obj1.angle = tAngle;
      } else {
        obj1.scaleToWidth(
          ((bWidth / inputRef1.current.value) * 0.5 + (800 / bWidth - 1) * 2) *
            obj1.text.length
        );
      }
      obj1.setCoords();
    });

    editor.canvas.forEachObject((obj) => {
      if (obj.type === "image" && obj.id !== undefined) {
        rect1Move(obj.id);
      }
    });

    editor.canvas.renderAll();
  }

  function scaleObjectsOnCanvas(resizeRatio) {
    const objects = editor.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      objects[i].left = objects[i].left * resizeRatio;
      objects[i].top = objects[i].top * resizeRatio;
      objects[i].scaleX = objects[i].scaleX * resizeRatio;
      objects[i].scaleY = objects[i].scaleY * resizeRatio;
      objects[i].setCoords();
    }
    editor.canvas.renderAll();
  }

  function rect1Move(id) {
    editor.canvas.forEachObject((obj) => {
      editor.canvas.forEachObject((obj1) => {
        if (obj.id === id && obj1.type === "i-text" && obj1.id === id) {
          const pointX =
            (bWidth === 800 ? 50 : 30) *
            pDimension *
            Math.cos(((obj.angle - 45) * Math.PI) / 180.0);
          const pointY =
            (bWidth === 800 ? 50 : 30) *
            pDimension *
            Math.sin(((obj.angle - 45) * Math.PI) / 180.0);
          // set center coords of text in front of image and rotate with it
          obj1.left = pointX + obj.left - 2.5;
          obj1.top = pointY + obj.top - 5;
          obj1.setCoords();
        }
      });
    });
  }

  function Addtext() {
    editor.canvas.add(
      new fabric.IText("Tap and Type", {
        left: 50,
        top: 100,
        fontFamily: "arial black",
        fill: "#333",
        fontSize: 25,
      })
    );
  }

  /**
   * Copy a string to clipboard
   * @param  {String} string         The string to be copied to clipboard
   * @return {Boolean}               returns a boolean correspondent to the success of the copy operation.
   * @see https://stackoverflow.com/a/53951634/938822
   */
  function copyToClipboard(string) {
    let textarea;
    let result;

    try {
      textarea = document.createElement("textarea");
      textarea.setAttribute("readonly", true);
      textarea.setAttribute("contenteditable", true);
      textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);

      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand("copy");
    } catch (err) {
      result = null;
    } finally {
      document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const copyHotkey = isMac ? "⌘C" : "CTRL+C";
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  }

  function oxer(e) {
    const number = new fabric.IText((numbers.length + 1).toString(), {
      fontSize: 20,
      fill: "red",
      width: 10,
      height: 5,
      angle: 0,
    });

    setRotatorOnly(number);
    number.lockMovementX = true;
    number.lockMovementY = true;
    number.setControlVisible("mtr", false);
    number.controls = false;
    numbers.sort((a, b) => a.id - b.id);
    number.id = numbers.length;
    numbers.push(number);

    editor.canvas.add(number);

    fabric.Image.fromURL("../assets/" + e + ".png", function (img) {
      var jump = img.set({ left: 150, top: 150 });
      jump.id = number.id;
      jump.scaleToWidth((bWidth / pSize.width) * jumpWidth);
      jump.on("moving", () => rect1Move(jump.id));
      jump.on("rotating", () => rect1Move(jump.id));
      editor.canvas.add(setRotatorOnly(jump));
      jumps.push(jump);
      editor.canvas.centerObject(jump);
      number.left = jump.getCenterPoint().x - 30;
      number.top += jump.getCenterPoint().y + 30;
      handleSize();
      rect1Move(jump.id);
    });
  }

  function setRotatorOnly(fab) {
    fab
      .setControlVisible("tl", false)
      .setControlVisible("tr", false)
      .setControlVisible("bl", false)
      .setControlVisible("br", false)
      .setControlVisible("ml", false)
      .setControlVisible("mt", false)
      .setControlVisible("mr", false)
      .setControlVisible("mb", false)
      .setControlVisible("mtr", true);
    return fab;
  }
};

export default App;
